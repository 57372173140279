#resultBody{
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#resultHeader{
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 10px;
    background-color: rgb(35, 74, 154);
    padding: 5px 20px 5px 30px;
    text-align: center;
    color: white;
    border-radius: 5px;
}