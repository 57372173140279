.BlockWindowContent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    color: white;
    padding: 20px;
    border-radius: 10px;
    background-color: rgb(17, 45, 105);
}

.TaskBlockName{
    margin-bottom: 20px;
}