.ClarifyingWindowContent{
    background-color: rgb(17, 45, 105);
    color: white;
    border-radius: 5px;
}

.ClarifyingWindowContent h1{
    width: 400px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
}

#NoClarifying{
    margin-left: 50px;
    width: 100px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
}
#YesClarifying{
    margin-right: 50px;
    width: 100px;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

#ClarifyingButtons{
    display: flex;
    justify-content: space-between;
}