.QuestHeader {
  position: relative;
  z-index: 101;
  border-radius: 5px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.ActionQuestButton {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  position: absolute;
  z-index: 102;
  margin-left: 485px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .exitToQuestButtonImage{
    width: 7vw;
    margin-top: -1vh;
  }
  .ActionQuestButton {
    margin-left: 83vw;
  }
}
