.ActionButton{
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    margin-right: 5px;
    margin-top: 4px;
    cursor: pointer;
}

.TaskBlockName{
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgb(35, 74, 154);
}



.BlockNameForm input{
    width: 500px;
    font-family: sans-serif;
    letter-spacing: 1px;
    text-align: center;
    background-color: transparent;
    border: 0px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    padding: 10px 0px 10px 0px;
}

.BlockNameForm input:focus{
    outline: none;
}

@media screen and (max-width: 500px) {
    .BlockNameForm input{
        width: 80vw;
    }
}