* {
    margin: 0;
    padding: 0;
}

#addQuestButton {
    position: fixed;
    right: 20px;
    bottom: 10px;
    cursor: pointer;
}
