* {
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
  background-color: rgb(15, 45, 105);
  height: 80px;
  color: white;
  position: fixed;
  z-index: 100;
}

.header img {
  cursor: pointer;
  float: right;
  margin-right: 15px;
  margin-top: 15px;
}

#mainLogo{
  position: fixed;
  left: 15px;
  top: -2px;
  width: 100px;
}

#mainLogo_quest{
  position: absolute;
  width: 80px;
  left: calc(50vw - 40px);
  margin-top: 10px;
}

@media screen and (max-width: 500px) {
  #mainLogo_quest{
    position: absolute;
    width: 10vh;
    left: calc(50vw - 5vh);
    margin-top: 2vh;
  }
  .profile{
    width: 6vh;
  }
}
