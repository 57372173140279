/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */

.parallelBlock {
  width: 500px;
}
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

#blockHeader_View{
  text-align: center;
  font-size: 20px;
  background-color: rgb(17, 45, 105);
  min-width: 492px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: right;
  align-items: center;
  font-weight: bold;
  font-size: 25px;
}

.blockView{
  margin-bottom: 20px;
}
.remainingCountHeader{
  text-align: center;
}

#blockCounter{
  position: absolute;
  background-color: rgb(35, 74, 154);
  padding-left: 5px;
  padding-right: 5px;
  height: 23.5px;
  border-radius: 5px;
}

#blockName_View{
  flex: none;
  position: relative;
  text-align: center;
  width: 100%;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: -50px;
  background-color: none;
}
.flickity-prev-next-button.next {
  right: -50px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 0px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

@media screen and (max-device-width: 500px) {
  #blockHeader_View{
    width: 60vw;
  }
  #TasksListView{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-left: calc(50vw - 35vw);
  }
  .Slider{
    position: relative;
    margin-left: 10vw;
    width: 100vw;
  }
  .blockView{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
  }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    width: 100vw;
  }

  .flickity-slider {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5vw;
  }
  .remainingCountHeader{
    text-align: center;
    margin-left: 30vw;
  }
  
}
