.ActionMenuContent{
    position: absolute;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 160;
}

.ActionMenuContentQuest{
    position: absolute;
    right: 35vw;
    top: -70px;
}

.ActionMenuContentBlock{
    position: absolute;
    top: 285px;
    right: 240px;
}