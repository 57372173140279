#QuestFooter{
    width: 100%;
    background-color: rgb(15, 45, 105);
    position: fixed;
    bottom: 0px;
    z-index: 101;
    height: 80px;
    color: white;
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}