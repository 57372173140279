.ClarifyingWindow{
    height: 100%;
    width: 100%;
    top: 0px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 104;
    background-color: rgba(0, 0, 0, 0.5);
}