.TaskWindow{
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 105;
    background-color: rgba(0, 0, 0, 0.5);
}

.TaskExitButtonImage{
    cursor: pointer;
}

.TaskExitButton{
    position: relative;
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    margin-left: -10px;
    z-index: 106;
}

#TaskWindowContainer{
    display: flex;
    align-items: baseline;
}