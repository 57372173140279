.TaskBlockObject .ActionButton{
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%);
}

.TaskBlockButton{
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

.TaskBlock{
    padding-top: 2px;
    text-align: center;
    word-break: normal;
    text-align: center;
    transform: translate(0, 25%);
}


.TaskBlockObject{
    position: relative;
    background-color: rgb(35, 74, 154);
    border-radius: 5px;
    width: 300px;
    margin-bottom: 8px;
}
