* {
  margin: 0;
  padding: 0;
}

#ConfidenceBox{
  display: flex;
  margin-bottom: 10px;
  margin-top: -10px;
  margin-left: -10px;
}

#loginFormCase{
  background-color: rgb(35, 74, 154);
  width: 100%;
  height: 100%;
  position: fixed;
}

#hseLogo{
  height: 50px;
  width: 50px;
  position: absolute;
  margin-left: 380px;
  margin-top: -15px;
}

#ConfidenceBox a{
  color: white;
  font-weight: bold;
  font-size: 10px;
  text-align: left;
  margin-left: 2px;
}

.login {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main__form {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(35, 74, 154);
  width: 400px;
  padding: 32px 32px 15px 32px;
  border-radius: 10px;
  font-family: sans-serif;
  letter-spacing: 1px;
  border: solid;
  border-color: white;
}

.main__form input[type="email"],
.main__form input[type="password"],
.main__form input[type="submit"] {
  position: relative;
  font-family: sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  margin-bottom: 30px;
}

.main__form input[type="password"] {
  margin-bottom: 0px;
}

.main__form input[type="submit"] {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin-bottom: 0;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: white;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}

.main__form input[type="submit"]:focus,
.main__form input[type="submit"]:hover {
  background-color: rgb(232, 232, 232);
}

.main__form input[type="email"],
.main__form input[type="password"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
}

.main__form h1 {
  text-align: center;
  margin: 0 0 32px 0;
  color: white;
}

.wrong_data {
  color: rgb(255, 61, 61);
  margin-bottom: 20px;
  font-size: 20px;
}

#loginLogo{
  position: fixed;
  left: calc(50% - 125px);
  top: 20px;
  width: 250px;
}

@media screen and (max-device-width: 500px) {
  .main__form {
    width: 70vw;
    padding-bottom: 8px;
  }

  .wrong_data {
    font-size: 16px;
    padding-bottom: 0px;
  }

  .main__form h1 {
    margin-bottom: 15px;
  }

  #hseLogo{
    margin-right: 30vw;
    margin-top: -3vh;
    position: absolute;
    scale: 0.8;
  }

  #loginLogo{
    position: fixed;
    width: 70vw;
    left: calc(50vw - 35vw);
    z-index: 2;
  }

  .main__form input[type="email"],
  .main__form input[type="password"],
  .main__form input[type="submit"] {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .main__form input[type="password"] {
    margin-bottom: 0px;
  }
}
