#ParticipantWindow{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 130;
    top: 0px;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}