#ParticipantsListWindow{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 150;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#ParticipantsListWindowContainer{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-left: 60px;
}

#closeParticipantsWindow{
    width: 35px;
    height: 35px;
    margin-left: -15px;
    margin-top: -10px;
}

#ParticipantsListWindowFlexContainer{
    display: flex;
}