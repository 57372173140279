.BackgroungActionMenu{
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 102;
    left: 0px;
    top: 0px;
}