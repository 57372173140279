.AddQuestWindow{
    height: 100%;
    width: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 102;
}
.AddQuestExitButtonImage{
    cursor: pointer;
}

.AddQuestExitButton{
    position: relative;
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    margin-top: -250px;
    margin-left: -18px;
}

@media screen and (max-width: 500px){
    .AddQuestExitButton{
        margin-left: -20px;
    }
}