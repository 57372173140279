#FinishContainer{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgb(3, 187, 228);
    z-index: 110;
    top: 0px;
    color: white;
}

#Congratilations{
    position: absolute;
    z-index: 111;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
}

#balloon-container {
    height: 100vh;
    padding: 1em;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    transition: opacity 500ms;
  }
  
  .balloon {
    height: 125px;
    width: 105px;
    border-radius: 75% 75% 70% 70%;
    position: relative;
  }
  
  .balloon:before {
    content: "";
    height: 75px;
    width: 1px;
    padding: 1px;
    background-color: #FDFD96;
    display: block;
    position: absolute;
    top: 125px;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  .balloon:after {
      content: "▲";
      text-align: center;
      display: block;
      position: absolute;
      color: inherit;
      top: 120px;
      left: 0;
      right: 0;
      margin: auto;
  }
  
  @keyframes float {
    from {transform: translateY(100vh);
    opacity: 1;}
    to {transform: translateY(-300vh);
    opacity: 0;}
  }