.AddQuestWindowContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  color: white;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(17, 45, 105);
}

#addingImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#addingImageContainer {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  overflow: hidden;
  width: 200px;
  height: 220px;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 20px;
}

.chooseTypeOfQuest div{
  font-weight: bold;
  margin-right: 5px;
}

#addingImagesElement {
  margin-top: -21px;
  margin-right: 5px;
}

.chooseTypeOfQuest {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

textarea {
  resize: none;
}
#QuestName{
  width: 220px;
  margin-bottom: 10px;
  padding: 5px 0px 5px 0px;
}
#descriptionArea{
  height: 70px;
  width: 220px;
  padding: 5px 0px 5px 0px;
}

.lowerButtons {
  width: 100%;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AddQuestWindowContentForm {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
}

#questType{
  width: 200px;
}

@media screen and (max-width: 500px){
  #addingImageContainer{
    width: 40vw;
    height: 220px;
  }

  #questType{
    width: 50vw;
  }
}
