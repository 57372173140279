.BlockWindow{
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 103;
    background-color: rgba(0, 0, 0, 0.5);
}

#BlockWindowContainer{
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.BlockExitButtonImage{
    cursor: pointer;
}

.BlockExitButton{
    position: relative;
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    margin-left: -18px;
}