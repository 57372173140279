* {
    margin: 0;
    padding: 0;
}

.QuestCard {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    background-color: green;
    margin-bottom: 10px;
    width: 482.8px;
    cursor: pointer;
}

.Quest_image_container{
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 200px;
    height: 220px;
    border-radius: 5px;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.QuestCard img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.QuestInfo{
    color: white;
    margin-left: 10px;
}

.QuestInfo h1{
    text-align: left;
    padding-left: 5px;
    margin-left: 5px;
    font-size: 22px;
    width: 280px;
}

.QuestInfo textarea{
    width: 280px;
    height: 150px;
    background-color:inherit;
    border: none;
    color: white;
    margin-left: 10px;
    margin-top: 5px;
}

.QuestDate{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 59, 0);
    border-radius: 5px;
    margin-left: 30px;
    font-weight: bold;
    font-size: 20px;
}

.ButtonCard {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    width: 482.8px;
  }

  .readDescription{
    cursor: pointer;
  }

  @media screen and (max-device-width: 500px){
    .QuestCard{
        width: 90vw;
    }

    .QuestInfo h1{
        width: 60vw;
    }
    
    .QuestInfo textarea{
        width: 60vw;
        height: 20vh;
    }

    .Quest_image_container{
        height: 20vh;
    }

    .QuestDate{
        font-size: 15px;
    }
  }