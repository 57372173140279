#ParticipantsListWindowContent{
    background-color: rgb(17, 45, 105);
    color: white;
    border-radius: 5px;
    padding: 20px;
}

#ParticipantsListHeader{
    margin-bottom: 10px;
    font-size: 23px;
    background-color: rgb(35, 74, 154);
    padding: 0px 10px 0px 10px;
    border-radius: 5px;
}

#deleteParticipantButton{
    height: 25px;
    width: 25px;
    margin-top: -10px;
    cursor: pointer;
    display: block;
}

#participantsElement{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 18px;
}

#ParticipantsList{
    display: flex;
    flex-direction: row;
    justify-content: center;
}