.CreationMenu {
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  position: fixed;
  height: 100%;
  border-radius: 10px;
  transition: 2s;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.CreationMenuBody {
  background-color: rgb(0, 133, 255);
  border-radius: 10px 0 0 10px;
}

#CreationMenuHeader {
  text-align: center;
  background-color: black;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  height: 20px;
  margin-top: 5px;
  padding: 5px 0px 5px 0px;
  width: 130px;
  margin-left: 10px;
}

.OpenedArrow,
.ClosedArrow {
  cursor: pointer;
  margin: 0 0 0 0;
  background-color: rgb(0, 133, 255);
  border-radius: 20px 0 0 20px;
  padding: 10px;
}

.ClosedArrow {
  transform: scaleX(-1);
  border-radius: 0 20px 20px 0;
}

#ConsistentName {
  position: relative;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  width: 140px;
  margin-left: 5px;
}

#ParallelName {
  width: 140px;
  position: relative;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  margin-left: 15px;
}

@media screen and (max-device-width: 500px){
  .CreationMenu{
    position: fixed;
    margin-right: 5000px;
  }
}
