.TaskView {
  background-color: rgb(35, 74, 154);
  color: white;
  width: fit-content;
  padding: 0px 8px 8px 8px;
  margin-bottom: 20px;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.taskAction{
    position: relative;
    padding-top: 5px;
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    width: 250px;
    left: calc(50% - 125px);
}

#taskName, #attentionHeaderText , .simpleTaskText, #taskTime {
  text-align: center;
}

#sendAnswer{
  margin-top: 10px;
  width: 200px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
  text-align: center;
  position: relative;
  left: calc((50% - 100px));
  border-radius: 5px;
  border: none;
  background-color: white;
  cursor: pointer;
}

.textAreasTask {
  width: 460px;
  max-height: 70px;
  padding-left: 5px;
  padding-top: 10px;
  border-radius: 5px;
  resize: none;
}

#asnwerInput{
  width: 460px;
  height: 20px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

#descrHeader {
  font-size: 20px;
}

#openCameraText{
    text-align: center;
    font-size: 22px;
}

#filesHeaderText{
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
}

#filesDownloadButton{
    cursor: pointer;
    margin-bottom: 10px;
    scale: 1.3;
}

#attentionHeaderText{
    text-align: center;
    margin-top: 2px;
}

.attentionDescr{
    margin-bottom: 15px;
}
.simpleTaskText{
    text-align: center;
    font-size: 20px;
}

.maxPoints{
  text-align: left;
}

.points{
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 22px;
}

.startButton {
  position: relative;
  left: calc(50% - 28.5px);
  margin-top: 20px;
  scale: 1.3;
  cursor: pointer;
}

.scanner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(17, 45, 105);
  z-index: 105;
}

#qrCodeContainer {
  position: relative;
  width: 300px;
  height: 330px;
  border-radius: 7px;
  overflow: hidden;
}

#qr-shaded-region {
  border-width: 50px !important;
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto !important;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

@media screen and (max-device-width: 500px){
  .TaskView {
    margin-bottom: 20px;
    border-radius: 5px;
    width: 90vw;
  }

  .textAreasTask, #asnwerInput{
    width: 80vw;
  }

}
