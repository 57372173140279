.Headers {
  top: 19px;
  width: 100%;
  position: fixed;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

#waitingBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  top: calc(50vh + 10px);
}

#CounterQuestPage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: calc(30vh);
}

[hidden] {
  display: none
}

#createTeamDescription{
  margin-top: 20px;
  font-size: 20px;
  width: 360px;
  text-align: center;
}

#notParticipantButtons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  margin-top: 30px;
}

#TeamDescription{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#notParticipantButtons button{
  width: 110px;
}

#NotParticipantDescription{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#questStartDataCounter {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
}

.taskParticipating {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 15vh;
}

.QuestHeader {
  position: relative;
  top: 35px;
  background-color: rgb(35, 74, 154);
  width: 500px;
  text-align: center;
  font-size: 25px;
  color: white;
}

.CreationMenu {
  width: 150px;
  top: 0%;
  right: 25px;
}

.exitToQuestButton {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  position: absolute;
  z-index: 105;
  left: 3px;
  height: 50px;
  top: -5px;
  scale: 1.5;
}

.exitToQuestButtonImage {
  cursor: pointer;
}

#npcQRCode{
  display: flex;
  justify-content: center;
}
@media screen and (max-device-width: 500px) {
  .exitToQuestButtonImage{
    width: 7vw;
    margin-top: -1vh;
  }

  .QuestHeader {
    width: 90vw;
  }

  /* #waitingBody {
    margin-top: 10vh;
  } */
}