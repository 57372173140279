#Blocks {
  padding-top: 130px;
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
}
#BlocksField {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}

.Arrow {
  scale: 2;
  transform: scaleX(1.5);
  margin-top: 15px;
  margin-bottom: 10px;
}

.Element {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
