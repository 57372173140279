.SaveBlockButton {
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  position: fixed;
  top: 50%;
  left: -130px;
  border-radius: 0px 0px 10px 10px;
  transition: 1s;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  background-color: rgb(17, 45, 105);
  cursor: pointer;
  width: 170px;
  height: 50px;
}

.SaveBlockButtonBody {
  background-color: rgb(35, 74, 154);
  padding: 5px 8px 5px 8px;
  margin-top: 7px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  border-radius: 10px;
}
